<template>
    <div class="container_bug">
        <img src="../../assets/successIcon2.png" />
        <div class="success_title">支付已经完成</div>
        <div class="order_item">订单编号：{{dataList.order_sn}}</div>
        <div class="order_item">支付金额：{{dataList.goods_amount}}</div>
        <div class="order_item">下单时间：{{dataList.create_time}}</div>
        <div class="order_item">支付方式：{{dataList.order_amount=0?'线下支付':'微信支付'}}</div>
        <div class="order_item">支付时间：{{dataList.update_time}}</div>

        <div class="footerBtn">
            <div class="goGoods" @click="goGoods">继续逛逛</div>
            <div @click="lookOrder">查看订单</div>
        </div>
    </div>
</template>
<script>
import {orderDetail_api} from "@/api/deal"
export default {
  data() {
    return {
      dataList:null
    };
  },
  created() {
    document.title = "订单详情";
    this.queryList = this.$route.query;
    this.getList();
  },
  methods:{
    getList() {
      orderDetail_api(this.queryList.id).then(res=>{
        if(res.code==0){
          this.dataList = res.data
        }
      })
    },
    lookOrder(){
      this.$router.push(`./orderDetail?id=${this.queryList.id}`)
    },
    goGoods(){
      this.$router.push(`./index`)
    }
    
  }
};
</script>
<style>
.container_bug {
  padding: 0 87px;
  box-sizing: border-box;
  font-size: 30px;
  color: rgba(26, 25, 37, 1);
}
.container_bug img {
  width: 200px;
  height: 200px;
  display: block;
  margin: 200px auto 30px;
  text-align: center;
  font-size: 30px;
  color: rgba(26, 25, 37, 1);
}
.order_item {
  margin-bottom: 20px;
  font-weight: bold;
}
.success_title {
  text-align: center;
  font-weight: 800;
  margin-bottom: 112px;
}
.footerBtn {
  display: flex;
  margin-top: 150px;
}
.footerBtn div {
  width: 304px;
  height: 97px;
  box-shadow: 0px 0px 10px 0px rgba(120, 120, 120, 0.15);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 2px solid #2a2828;
  text-align: center;
  line-height: 97px;
  font-size: 35px;
  color: #2a2828;
  font-weight: bold;
  letter-spacing:3px
}
.footerBtn .goGoods {
  width: 304px;
  height: 97px;
  background: #2a2828;
  box-shadow: 0px 0px 10px 0px rgba(120, 120, 120, 0.15);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  text-align: center;
  line-height: 97px;
  font-size: 35px;color:#fff;
  margin-right:40px
}
</style>